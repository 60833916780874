<template>
  <div>
    <!-- <JobDetailPc v-if="$screen.breakpoint === 'pc'" />
    <JobDetailMobile v-else /> -->
    <div
      v-show="isToast"
      id="toast-default"
      ref="toastRef"
      class="fixed flex flex-row mx-auto z-[99999] rounded-[4px] bg-gray-700
              pc:top-[75px] pc:left-[calc((100%-480px)/2)] pc:w-[480px] pc:h-12 pc:pl-4 pc:py-3.5
              mobile:top-18 mobile:left-[calc((100%-343px)/2)] mobile:w-[343px] mobile:h-[42px] mobile:pl-3 mobile:py-[11px]
              "
      role="alert"
    >
      <div class="items-center justify-center">
        <img
          src="@/assets/icon-new/jd/icon-link.svg"
          width="20"
          height="20"
        >
      </div>
      <div
        class="font-bold text-white pc:ml-2 pc:text-base pc:leading-[1.25] mobile:ml-1.5 mobile:text-sm mobile:leading-[1.29]"
      >
        링크를 복사했어요.
      </div>
    </div>
    <JobDescriptionPc
      v-if="$screen.breakpoint === 'pc'"
      @toast-view="toastShow"
    />
    <JobDescriptionMobile
      v-else
      @toast-view="toastShow"
    />
  </div>
</template>

<script>
// import JobDetailPc from './JobDetail_pc.vue';
// import JobDetailMobile from './JobDetail_mobile.vue';
import JobDescriptionPc from './JobDescription_pc.vue';
import JobDescriptionMobile from './JobDescription_mobile.vue';

export default {
  name: 'JobDetail',
  components: {
    // JobDetailPc,
    // JobDetailMobile,
    JobDescriptionPc,
    JobDescriptionMobile,
  },
  data() {
    return {
      isToast: false,
    };
  },
  created(){
    window.fbq('track', 'cookapps_careers_detail_view');
  },
  methods: {
    toastShow() {
      this.isToast = true;
      setTimeout(() => {
        this.$refs.toastRef.classList.remove('toast-in');
        this.$refs.toastRef.classList.add('toast-out');
      }, 3000);
      setTimeout(() => {
        this.isToast = false;
        this.$refs.toastRef.classList.remove('toast-out');
        this.$refs.toastRef.classList.add('toast-in');
      }, 3100);
    }
  }
};
</script>

<style scoped>
.toast-in {
  animation: slideIn 0.1s ease-out forwards;
}

.toast-out {
  animation: slideOut 0.1s ease-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 100;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 100;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
