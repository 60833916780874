<template>
  <section class="pageTopBanner">
    <img
      class="pgTopBnr__bg"
      src="@/assets/common/topBanner_bg.png"
    >
    <div
      class="pgTopBnr__title"
    >
      <span>
        <p 
          class="mb-1 font-bold text-sm leading-[1.29]
                tablet:mb-2 tablet:font-normal tablet:text-2xl tablet:leading-[1.27]
                pc:mb-3 pc:font-medium pc:text-3xl
          "
        >
          {{ bannerData.title }}
        </p>
        <p
          class="text-3xl font-bold 
                tablet:text-5xl tablet:leading-[1.23] 
                pc:text-6xl pc:leading-[normal] pc:tracking-[normal]"
        >{{ bannerData.jobName }}</p>
      </span>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PageTopBanner',
  props: ['data'],
  data() {
    return {
      bannerData: this.data
    };
  },
};
</script>

<style lang="scss" scoped>
.pageTopBanner{
  position: relative;
  height: 400px;
}
.pgTopBnr{
  &__bg{
    position: absolute;
    z-index: 0;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  &__title{
    height: 100%;
    font-family: $fontfamily;
    font-weight: 600;
    font-size: clamp(70px, 6.7vw, 80px);
    line-height: 1.14;
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
    z-index: 1;
    color: #FFF;
    position: relative;
    span{
      position: absolute;
      bottom: 56px;
    }
    :is(.tablet) &{
      font-size: 56px;
      margin: 0 32px;
      word-break: keep-all;
      span{
        position: absolute;
        bottom: 40px;
      }
    }
    :is(.mobile) &{
      font-size: 36px;
      margin: 0 16px;
      word-break: keep-all;
      span{
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
:is(.mobile){
  .pageTopBanner{
    height: rem(360);
  }
  .icon-cook3{
    font-size: 24px;
  }
}
:is(.tablet){
  .pageTopBanner{
    height: rem(640);
  }
  .icon-cook3{
    font-size: 36px;
  }
}
</style>
