<template>
  <div>
    <div class="relative bg-white mb-[120px]">
      <PageTopBanner :data="pageTopBanner" />
      <div class="font-Pretendard max-w-7xl w-[90%] mx-auto">
        <div
          v-observe-visibility="visibilityChanged"
          style="height: 1px;"
        />
        <div class="mt-18">
          <div class="flex flex-row">
            <div
              ref="leftSide"
              class="max-w-[805px]"
            >
              <div class="">
                <div
                  class="jobDescription_desc"
                  v-html="jobDesc"
                />
              </div>
              <div class="w-full mt-12 p-8 rounded-[8px] bg-gray-50 bg-opacity-70">
                <div
                  class="font-bold text-gray-600 text-lg leading-[1.33]"
                >
                  쿡앱스 채용 관련 참고 사항
                </div>
                <div class="recruit_noti">
                  <ul class="mt-2.5">
                    <li>해당 공고는 수시 채용으로, 채용 완료 시 조기에 마감될 수 있습니다.</li>
                    <li>쿡앱스 내 전 직군 중복지원 가능하며, 응시 횟수 제한이 없습니다.</li>
                    <li>입사지원 서류에서 허위 기재 사실이 발견될 경우, 입사가 취소될 수 있습니다.</li>
                    <li>제출해 주신 과제는 검토 후 폐기 예정이며, 쿡앱스에서 임의로 이용하지 않습니다.</li>
                    <li>과제 전형을 위해 링크로 첨부된 파일의 무단 공유, 배포 및 사용을 금지합니다. 협의 없이 무단으로 공유, 사용, 배포할 경우 책임을 질 수 있으며, 진행 중인 전형은 중단되고 이후 쿡앱스 지원은 불가합니다.</li>
                    <li>쿡앱스에서 수행한 과제를 개인 포트폴리오로 활용 가능합니다. 다만, '쿡앱스 OO 직무 과제 전형' 작업물 임을 명시해 주세요. (리소스 받으신 경우, 해당 출처 기재 바랍니다.)</li>
                    <li>채용 서류 반환 고지 : 쿡앱스에 제출한 채용서류는 반환 받을 수 있습니다. 반환 희망시 recruit@cookapps.com로 문의 부탁드리며, 접수 후 14일 내 요청 서류를 발송해 드립니다. (홈페이지 또는 전자우편을 통해 제출한 서류는 반환 청구 대상에 포함되지 않습니다.)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="w-24" />
            <div
              ref="rightSide"
              class="min-w-[380px] h-fit transition-transform duration-100"
            >
              <div class="flex flex-row pb-5 border-b border-gray-200">
                <div class="w-[143px]">
                  <span class="text-2xl leading-[1.27] text-gray-600">
                    {{ jobTypeID === 20 ? 'Team' : '소속' }}
                  </span>
                </div>
                <div>
                  <span class="text-2xl font-bold leading-[1.27] text-orange-500">
                    {{ belong }}
                  </span>
                </div>
              </div>
              <div class="flex flex-row py-5 border-b border-gray-200">
                <div class="w-[143px]">
                  <span class="text-2xl leading-[1.27] text-gray-600">
                    {{ jobTypeID === 20 ? 'Experience' : '경력' }}
                  </span>
                </div>
                <div>
                  <span class="text-2xl font-bold leading-[1.27] text-orange-500">
                    {{ yearsType }}
                  </span>
                </div>
              </div>
              <div class="flex flex-row pt-5">
                <div class="w-[143px]">
                  <span class="text-2xl leading-[1.27] text-gray-600">
                    {{ jobTypeID === 20 ? 'Contract' : '고용 형태' }}
                  </span>
                </div>
                <div>
                  <span class="text-2xl font-bold leading-[1.27] text-orange-500">
                    {{ recruitType }}
                  </span>
                </div>
              </div>
              <div
                class="w-full mt-12"
              >
                <button
                  type="button"
                  class="flex justify-center items-center w-full h-16 py-[17px] rounded-5xl bg-orange-500 hover:bg-orange-600 active:bg-orange-600"
                  @click="$router.push({path: '/recruit/apply', query: {jobId: id, jobTypeID}});"
                >
                  <span
                    class="text-[24px] font-bold text-white text-center leading-[1.25] -tracking-[0.2px]"
                  >바로 지원하기</span>
                </button>
              </div>
              <div class="w-full mt-3">
                <button
                  type="button"
                  class="flex justify-center items-center w-full h-16 py-[17px] rounded-5xl bg-white border border-gray-200 hover:bg-gray-50 active:bg-gray-100"
                  @click="$router.push('/faq')"
                >
                  <span
                    class="text-[24px] leading-[1.25] -tracking-[0.2px] text-center text-gray-600"
                  >
                    자주 묻는 질문 보기
                  </span>
                </button>
              </div>
              <div class="flex flex-col justify-center mt-7">
                <div class="text-center font-bold text-gray-400 text-base leading-[1.25] tracking-[3px]">
                  SHARE
                </div>
                <div class="flex flex-row justify-center mt-3">
                  <a
                    class="mr-7"
                    href="#"
                    @click="kakaoShare"
                  >
                    <!-- kakaotalk icon -->
                    <svg
                      class="text-gray-400 fill-current hover:fill-current hover:text-gray-700"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 3c9.113 0 16.5 5.87 16.5 13.11 0 7.242-7.387 13.112-16.5 13.112-.268 0-.603-.015-.95-.037l-.348-.023c-.812-.06-1.58-.14-1.58-.14L8.036 33.69a.367.367 0 0 1-.562-.374l1.193-6.391C4.338 24.56 1.5 20.599 1.5 16.11 1.5 8.87 8.887 3 18 3zm6.58 9.342a.881.881 0 0 0-.88.884v6.51c0 .489.395.885.88.885a.881.881 0 0 0 .876-.885v-2.008l.501-.505 2.331 3.082a.78.78 0 0 0 1.099.157.793.793 0 0 0 .156-1.107l-2.465-3.26 2.227-2.244a.782.782 0 0 0 .001-1.1.769.769 0 0 0-1.093 0l-2.757 2.777v-2.302a.88.88 0 0 0-.877-.884zm-10.113 0c-.129 0-.257.018-.38.054a1.242 1.242 0 0 0-.33.15 1.091 1.091 0 0 0-.254.227.96.96 0 0 0-.122.192c-.029.061-.145.395-.183.498l-.04.109-.049.13-.055.15-.062.168-.069.184-.074.2-.078.212-.083.224-.087.234-.09.243-.092.25-.094.254-.096.258-.096.261-.097.261-.096.26-.096.257-.093.253c-.03.082-.06.164-.092.247l-.088.24-.085.23a119.718 119.718 0 0 0-.158.427l-.072.193-.066.178-.06.16-.052.143-.045.122-.037.1-.029.077-.019.051a.794.794 0 0 0 .47 1.036.785.785 0 0 0 1.004-.484l.403-1.16h3.244l.403 1.16a.785.785 0 0 0 1.002.484.793.793 0 0 0 .481-1.009l-2.37-6.53-.008-.007c-.151-.397-.589-.687-1.11-.687zm-3.149.114H6.563a.812.812 0 0 0-.81.814c0 .45.362.815.81.815h1.545v5.739a.8.8 0 0 0 .233.561.782.782 0 0 0 .556.233h.173c.207 0 .41-.084.555-.232a.803.803 0 0 0 .233-.562v-5.739h1.46c.447 0 .81-.364.81-.815a.812.812 0 0 0-.81-.814zm8.04-.114a.88.88 0 0 0-.878.884v6.6c0 .408.329.74.735.74h3.231a.737.737 0 0 0 .735-.74.737.737 0 0 0-.735-.74h-2.21v-5.86a.88.88 0 0 0-.878-.884zM14.43 14.32c.012 0 .022.003.035.003l1.089 3.13H13.34z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <a
                    class="mr-7"
                    href="#"
                    @click="fbShare"
                  >
                    <!-- facebook icon -->
                    <svg
                      class="text-gray-400 fill-current hover:fill-current hover:text-gray-700"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.5 18.1c0-9.168-7.387-16.6-16.5-16.6S1.5 8.932 1.5 18.1c0 8.287 6.034 15.155 13.922 16.4V22.9h-4.19v-4.8h4.19v-3.657c0-4.16 2.463-6.458 6.232-6.458 1.805 0 3.694.324 3.694.324v4.085h-2.081c-2.05 0-2.689 1.28-2.689 2.593V18.1h4.576l-.731 4.799h-3.845v11.6C28.466 33.255 34.5 26.387 34.5 18.1"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <a
                    class="mr-7"
                    href="#"
                    @click="linkedInShare"
                  >
                    <!-- linkedin icon -->
                    <svg
                      class="text-gray-400 fill-current hover:fill-current hover:text-gray-700"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.775 3.002H5.213A2.187 2.187 0 0 0 3 5.162v25.673A2.191 2.191 0 0 0 5.214 33h25.561A2.196 2.196 0 0 0 33 30.835V5.163A2.194 2.194 0 0 0 30.775 3v.002zM11.897 28.563h-4.45V14.247h4.45v14.316zM9.674 12.29a2.58 2.58 0 1 1 2.58-2.581 2.58 2.58 0 0 1-2.58 2.58zm18.887 16.274h-4.444V21.6c0-1.66-.03-3.798-2.313-3.798-2.315 0-2.67 1.809-2.67 3.676v7.085H14.69V14.247h4.267v1.957h.058a4.677 4.677 0 0 1 4.21-2.313c4.506 0 5.336 2.964 5.336 6.82v7.853z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <a
                    @click.prevent="copyLink"
                  >
                    <!-- link icon -->
                    <svg
                      class="text-gray-400 fill-current hover:fill-current hover:text-gray-700"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.136 22.506 24.3 20.712l3.63-3.63c1.488-1.569 2.319-3.283 2.492-5.144.173-1.86-.353-3.456-1.575-4.786-1.33-1.223-2.925-1.748-4.786-1.575-1.86.174-3.575 1.005-5.144 2.493l-3.63 3.63-1.794-1.836 3.59-3.59c2.1-1.994 4.427-3.078 6.98-3.25 2.552-.173 4.745.604 6.58 2.332 1.728 1.835 2.505 4.028 2.332 6.58-.172 2.553-1.256 4.88-3.25 6.98l-3.59 3.59zm-3.63 3.63-3.59 3.59c-2.1 1.994-4.427 3.078-6.98 3.25-2.552.173-4.745-.604-6.58-2.332-1.728-1.835-2.505-4.028-2.332-6.58.172-2.553 1.256-4.88 3.25-6.98l3.59-3.59 1.835 1.794-3.63 3.63C6.582 20.486 5.75 22.2 5.578 24.062c-.172 1.86.352 3.456 1.575 4.786 1.33 1.223 2.925 1.748 4.786 1.575 1.86-.174 3.576-1.005 5.144-2.493l3.63-3.63 1.794 1.836zm0-14.437 1.795 1.795-10.809 10.808-1.794-1.794 10.808-10.809z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-screen mx-0 mt-24 border-b border-gray-200" />
      <div class="max-w-7xl w-[90%] mx-auto mt-18">
        <div v-if="morePositionList.length > 0">
          <div>
            <span class="text-4xl font-bold leading-[1.27] text-gray-800">
              <span class="text-orange-500">{{ pageTopBanner.title }}</span> 
              포지션 더 보기
            </span>
          </div>
          <div class="mt-[25px] border-b border-gray-500" />
          <div
            v-for="position in morePositionList"
            :key="position.id"
          >
            <div 
              class="flex flex-row justify-between border-b border-gray-200 cursor-pointer py-7"
              @click="$router.push(`/careers/jobDetail/${position.id}`)"
            >
              <div class="flex flex-row items-center">
                <div
                  class="text-xl font-bold leading-[1.3] text-gray-800 hover:text-orange-500 active:text-orange-500"
                  style="word-break: break-all;"
                >
                  <span>{{ position.alias }}</span>
                </div>
                <div class="flex">
                  <div
                    v-if="position.emergency"
                    class="ml-2"
                  >
                    <el-tooltip
                      :visible-arrow="false"
                      content="긴급 채용 직무입니다."
                      placement="bottom"
                      effect="dark"
                    >
                      <div>
                        🚨
                      </div>
                    </el-tooltip>
                  </div>
                  <div 
                    v-if="position.rank"
                    class="
                        ml-2 h-6 py-[3px] text-[14px] tracking-[-0.1px]
                        px-1.5 bg-orange-500 text-white font-bold leading-[1.29] rounded-[4px] 
                      "
                  >
                    {{ position.rank }}
                  </div>
                  <div 
                    v-if="position.jobTypeId === 0"
                    class="
                        ml-2 h-6 py-[3px] text-[14px] tracking-[-0.1px] w-[66px]
                        px-1.5 border-orange-500 text-orange-500 font-bold leading-[1.29] border rounded-[4px] 
                      "
                  >
                    집중 채용
                  </div>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="flex items-center justify-center text-base leading-[1.25] text-right text-gray-500">
                  {{ `${position.yearsType} · ${position.employType}` }}
                </div>
                <div class="ml-12 mr-8 flex items-center justify-center text-base leading-[1.25] text-right text-gray-500">
                  {{ pageTopBanner.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center mt-12">
          <router-link
            to="/careers/list"
          >
            <button
              type="button"
              class="flex bg-white rounded-5xl py-[17px] px-10 h-16 border border-gray-200 hover:bg-gray-50 active:bg-gray-100"
            >
              <span
                class="text-[24px] leading-[1.25] -tracking-[0.2px] text-center text-gray-600"
              >
                다른 채용 공고 더 보기
              </span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJobDetail, getOpenJobsByTypeId } from '@/api/job';
import { trackClick } from '@/api/trackClick';
import PageTopBanner from './components/PageTopBanner.vue';

export default {
  name: 'JobDetail',
  components: {
    PageTopBanner,
  },
  emits: ['toastView'],
  data() {
    return {
      showTopbanner: false,
      pageTopBanner: { title: '', jobName: ''},
      jobArr: [
        {
          idx: 0,
          title: '게임기획 & 시나리오',
          jobTypeID: 1,
        },
        {
          idx: 1,
          title: '아트',
          jobTypeID: 4,
        },
        {
          idx: 2,
          title: '프로그래밍',
          jobTypeID: 6,
        },
        {
          idx: 3,
          title: '마케팅',
          jobTypeID: 3,
        },
        {
          idx: 4,
          title: '고객관리',
          jobTypeID: 11,
        },
        {
          idx: 5,
          title: '인사/조직문화',
          jobTypeID: 12,
        },
        {
          idx: 6,
          title: '비디오/미디어',
          jobTypeID: 13,
        },
        {
          idx: 7,
          title: '데이터 분석',
          jobTypeID: 2,
        },
        {
          idx: 8,
          title: '전문연구요원',
          jobTypeID: 7,
        },
        {
          idx: 9,
          title: '프리랜서',
          jobTypeID: 14,
        },
        {
          idx: 10,
          title: '관계사',
          jobTypeID: 15,
        },
        {
          idx: 11,
          title: '인턴',
          jobTypeID: 16,
        },
        {
          idx: 11,
          title: 'REMOTE',
          jobTypeID: 20,
        },
        {
          idx: 12,
          title: '비웨일즈(관계사)',
          jobTypeID: 21,
        },
        {
          idx: 13,
          title: '경영지원',
          jobTypeID: 8,
        },
      ],
      jobTypeID: 0,
      jobType: '',
      belong: '',
      recruitType: '',
      yearsType: '',
      jobName: '',
      jobDesc: '',
      id: '',
      showShareModal: false,
      link: '',
      alertTxt: '',
      rightSideFixed: false,
      startTopY: 0,
      startTopFlag: true,
      stopPoint: 0,
      showIntroMore: false,
      jobContents: [],
      showTooltip: true,
      showRecruitNoti: false,
      morePositionList: [],
      buttonFixed: true,
    };
  },
  watch: {
    showShareModal() {
      this.alertTxt = this.showShareModal ? this.alertTxt : '';
    }
  },
  async beforeCreate() {
    const id = this.$route.params.id;
    this.id = id;
    const result = await getJobDetail({
      id: id
    });
    this.recruitType = result.data.jobDetail.recruitType === '인턴';
    this.jobName = result.data.jobDetail.alias;
    this.recruitType = result.data.jobDetail.employType;
    this.yearsType = result.data.jobDetail.yearsType;
    this.jobDesc = result.data.jobDetail.jobDescription;
    this.jobTypeID = result.data.jobDetail.jobTypeId;
    this.belong = '쿡앱스';
    if (this.jobTypeID === 15) this.belong = 'MINTRY';
    if (this.jobTypeID === 21) this.belong = '비웨일즈(관계사)';
    this.showTopbanner = true;
    this.link = window.document.location.href;
    this.jobContents = result.data.jobContents;

    this.pageTopBanner.title = this.jobArr.filter(item => item.jobTypeID === this.jobTypeID)[0].title;
    this.pageTopBanner.jobName = this.jobName;

    const jobTypeList = await getOpenJobsByTypeId(this.jobTypeID);
    this.morePositionList = jobTypeList.data.items.filter(item => item.id !== parseInt(this.id, 10));
  },
  async created() {
    const showTooltip = sessionStorage.getItem('showToolTip');
    this.showTooltip = showTooltip === 'false' ? false : true;

    const id = this.$route.params.id;
    this.id = id;
    // const result = await getJobDetail({
    //   id: id
    // });
    // this.recruitType = result.data.jobDetail.recruitType === '인턴';
    // this.jobName = result.data.jobDetail.alias;
    // this.recruitType = result.data.jobDetail.employType;
    // this.yearsType = result.data.jobDetail.yearsType;
    // this.jobDesc = result.data.jobDetail.jobDescription;
    // this.jobTypeID = result.data.jobDetail.jobTypeId;
    // this.belong = this.jobTypeID === 15 ? 'MINTRY' : '쿡앱스';
    // this.showTopbanner = true;
    // this.link = window.document.location.href;
    // this.jobContents = result.data.jobContents;

    // this.pageTopBanner.title = this.jobArr.filter(item => item.jobTypeID === this.jobTypeID)[0].title;
    // this.pageTopBanner.jobName = this.jobName;

    // const jobTypeList = await getOpenJobsByTypeId(this.jobTypeID);
    // this.morePositionList = jobTypeList.data.items.filter(item => item.id !== parseInt(this.id, 10));
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    visibilityChanged(isVisible) {
      if(!isVisible && this.$refs.leftSide.clientHeight && this.$refs.rightSide.clientHeight){
        this.stopPoint = this.$refs.leftSide.clientHeight - this.$refs.rightSide.clientHeight;
        this.startTopY = window.pageYOffset > 425 ? 425 : window.pageYOffset;
        this.startTopFlag = false;
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll(event) {
      const topWindow = window.pageYOffset;
      this.offset = topWindow - this.startTopY;
      if(this.offset < 15){
        this.offset = 0;
      }else if(this.offset > this.stopPoint || topWindow >= document.body.scrollHeight - window.innerHeight){
        this.offset = this.stopPoint;
      }
      this.$refs.rightSide.style.transform = 'translateY('+this.offset+'px)';
    },
    copyLink(){
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.value = this.link;
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$emit('toast-view');
    },
    kakaoShare(){
      const url = window.document.location.href;
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.jobName,
          imageUrl: 'http://cookapps.com/img/cookapps_openGraph.png',
          link: {
            mobileWebUrl: url,
            webUrl: url,
          }
        }
      });
    },
    fbShare(){
      FB.ui({
        method: 'share',
        href: window.document.location.href,
        quote: `쿡앱스 ${this.jobName} 부문 바로 지원하기!!!!`
      }, function(response){
        console.log('페북 공유 완료');
      });
    },
    fbShareTest(){
      let width = 600;
      let height = 700;
      let left = (window.screen.width / 2) - (width / 2);
      let top = (window.screen.height / 2) - (height / 2);
      let url = `https://www.facebook.com/share.php?u=${window.document.location.href}`;
      window.open(url, '', `left=${left}, top=${top}, width=${width}, height=${height}, _blank`);
    },
    linkedInShare(){
      let width = 600;
      let height = 700;
      let left = (window.screen.width / 2) - (width / 2);
      let top = (window.screen.height / 2) - (height / 2);
      let url = `https://www.linkedin.com/sharing/share-offsite/?url=${window.document.location.href}`;
      window.open(url, '', `left=${left}, top=${top}, width=${width}, height=${height}, _blank`);
    },
    async apply(){
      window.fbq('track', 'cookapps_detail_apply_click');
      const result = await trackClick({
        type: 'apply',
        value: 1
      });

      this.$router.push({path: '/recruit/apply', query: {jobId: this.id, jobTypeID: this.jobTypeID}});
    },
    showYoutube(videoKey) {
      document.documentElement.style.overflowY = 'hidden';
      this.$store.dispatch('app/setShowYoutube', true);
      this.$store.dispatch('app/setCurrentVideo', videoKey);
    },
    closeTooltip(){
      this.showTooltip = false;
      sessionStorage.setItem('showToolTip', 'false');
    },
  }
};
</script>

<style lang="scss">
.jobDescription_desc {
  word-break: break-all;

  & .subtitle {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #444;
  }

  & .list li {
    position: relative;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #444;
    & a {
      text-decoration: underline;
      color: #ff7f00;
      cursor: pointer;
    }
    & a:hover {
      opacity: 0.5;
    }
  }

  
  & .list li::before {
    content: '';
    display: inline-block;
    position: absolute;
    background-color: #666;
  }
  
  & .divider {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
  }

  & .desc {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #444;
  }

  & img {
    // width: 100%;
    padding-top: 8px;
  }

  & a:hover {
    text-decoration: underline;
    opacity: 0.5;
  }

  :is(.pc) & {
    & .subtitle {
      // font-weight: normal;
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 1.45;
      // font-size: 26px;
      // line-height: 1.46;
      letter-spacing: -0.2px;
    }
    & .list li {
      margin-bottom: 4px;
      padding-left: 30px;
      font-size: 18px;
      line-height: 34px;
      // font-size: 20px;
      // line-height: 1.7;
      letter-spacing: -0.2px;
    }
    & .list li::before {
      top: 14px;
      left: 12px;
      width: 4px;
      height: 4px;
    }
    & .desc {
      font-size: 18px;
      line-height: 34px;
      // font-size: 20px;
      // line-height: 1.7;
      letter-spacing: -0.2px;
      color: #444;
    }
    & blockquote {
      border-left: 3px solid #e1e1e1;
      margin-left: 12px;
    }
    & blockquote > * {
      margin-left: 20px;
    }
  }
}
.recruit_noti {
  word-break: keep-all;
  & ul {
    & li {
      position: relative;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: #666;
    }
    & li:last-child {
      margin-bottom: 0;
    }
    & li::before {
      content: '';
      display: inline-block;
      position: absolute;
      background-color: #666;
    }
  }
  :is(.pc) & {
    & ul {
      & li {
        margin-bottom: 6px;
        padding-left: 16px;
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: -0.1px;
      }
      & li:before {
        top: 12px;
        left: 3px;
        width: 3px;
        height: 3px;
      }
    }
  }
}
</style>
